<template>
  <div id="homepage" class="homepage">
    <!-- <el-select v-model="searchObj.days" placeholder="请选择" @change="changeDays">
      <el-option v-for="item in daysOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select> -->
    <div>
      <el-radio-group v-model="radioSpan" size="medium" @change="changeSpan">
        <el-radio-button label="0">七天</el-radio-button>
        <el-radio-button label="1">本月</el-radio-button>
        <el-radio-button label="2">本季</el-radio-button>
      </el-radio-group>
    </div>
    <el-row>
      <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
        <div class="box" style="height: 70px;position: relative">
          <div class="left_icon el-icon-share" style="color: #f7c114"></div>
          <div class="right">
            <div class="right1">SO总数 </div>
            <div class="right2">{{ this.count.so.soCount }}</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
        <div class="box" style="height: 70px; position: relative">
          <div class="left_icon el-icon-goods" style="color: #007b43"></div>
          <div class="right">
            <div class="right1">缺货</div>
            <div class="right2">{{ this.count.so.so3Count }}</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
        <div class="box" style="height: 70px; position: relative">
          <div class="left_icon el-icon-office-building" style="color: #c97586"></div>
          <div class="right">
            <div class="right1">待拣货</div>
            <div class="right2">{{ this.count.so.so4Count }}</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
        <div class="box" style="height: 70px; position: relative">
          <div class="left_icon el-icon-printer" style="color: #65318e"></div>
          <div class="right">
            <div class="right1">已发放</div>
            <div class="right2">{{ this.count.so.so8Count }}</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
        <div class="box" style="height: 70px; position: relative">
          <div class="left_icon el-icon-s-data" style="color: #9ba88d"></div>
          <div class="right">
            <div class="right1">ASN总数</div>
            <div class="right2">{{ this.count.asn.asnCount }}</div>
          </div>
        </div>
      </el-col>
      <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
        <div class="box" style="height: 70px; position: relative">
          <div class="left_icon el-icon-goods" style="color: #007b43"></div>
          <div class="right">
            <div class="right1">待入库</div>
            <div class="right2">{{ this.count.asn.asn0Count }}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <div class="box">
          <div class="box3" style="position: relative">
            <div id="main" :style="'width: 98%; height:'+mainHeight+'px; margin-top: 40px'"></div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="box">
          <el-row>
            <el-col :span="12">
              <div class="box3">
                <dv-decoration-9 style="width: 100px; height: 100px">{{ percent.perInventoryTurnOver }}</dv-decoration-9>
                <div class="title">库存周转率</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="box3">
                <dv-water-level-pond :config="config" style="width: 100px; height: 100px" />
                <div class="title">货位利用率</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="box">
          <el-row>
            <el-col :span="24">
              <div class="box3">
                <div class="title2">任务进行</div>
                <el-row :height="30">
                  <el-col :span="3">
                    <div class="title3">称重</div>
                  </el-col>
                  <el-col :span="21">
                    <el-progress :text-inside="true" :stroke-width="20" :percentage="percent.perPoundTask"
                      :status="percent.perPoundTask > 50 ? 'success' : 'warning'"></el-progress>
                    <!-- <el-progress :stroke-width="12" :percentage="percent.perPoundTask"></el-progress> -->
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="3">
                    <div class="title3">上架</div>
                  </el-col>
                  <el-col :span="21">
                    <el-progress :text-inside="true" :stroke-width="20" :percentage="percent.perUpTask"
                      :status="percent.perUpTask > 50 ? 'success' : 'warning'"></el-progress>
                    <!-- <el-progress :stroke-width="12" :percentage="percent.perUpTask"></el-progress> -->
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="3">
                    <div class="title3">盘点</div>
                  </el-col>
                  <el-col :span="21">
                    <el-progress :text-inside="true" :stroke-width="20" :percentage="percent.perPdTask"
                      :status="percent.perPdTask > 50 ? 'success' : 'warning'"></el-progress>
                    <!-- <el-progress :stroke-width="12" :percentage="percent.perPdTask"></el-progress> -->
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="3">
                    <div class="title3">下架</div>
                  </el-col>
                  <el-col :span="21">
                    <el-progress :text-inside="true" :stroke-width="20" :percentage="percent.perPickTask"
                      :status="percent.perPickTask > 50 ? 'success' : 'warning'"></el-progress>
                    <!-- <el-progress :stroke-width="12" :percentage="percent.perPickTask"></el-progress> -->
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="box">
          <div class="box3" style="position: relative">
            <div>
              <el-select v-model="accessKey" @change="changeAccess" size="mini" filterable placeholder="请选择门禁">
                <el-option v-for="(item, key) in accessDevices" :key="item.id" :label="item.name" :value="key">
                </el-option>
              </el-select>
            </div>
            <div id="access" style="width: 98%; height: 320px;overflow:hidden">
              <el-table :data="accessLog" style="width: 100%">
                <el-table-column fixed="" type="index" width="30">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="name" width="80" label="设备名">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="startTime" width="150" :formatter="formatDate" label="开始时间">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="endTime" width="150" :formatter="formatDate" label="结束时间">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="box">
          <div ref="videoContainer" class="box3" style="position: relative">
            <div ref="videoSelector">
              <el-select v-model="videoKey" @change="changeVideo" size="mini" filterable clearable placeholder="请选择监控">
                <el-option v-for="(item, key) in videoDevices" :key="item.id" :label="item.name" :value="key">
                </el-option>
              </el-select>
            </div>
            <div id="myPlayer" style="width: 98%; height: 320px;">
              <div style="text-align: center; line-height: 50px; font-size: 14px;color:#999;">选择摄像头查看视频</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="box">
          <div ref="poundContainer" class="box3" style="position: relative">
            <div ref="poundSelector">
              <el-select v-model="poundKey" @change="changePound" size="mini" filterable placeholder="请选择地磅">
                <el-option v-for="(item, key) in poundDevices" :key="item.id" :label="item.name" :value="key">
                </el-option>
              </el-select>
            </div>
            <div ref="poundData" style="width: 98%; height: 320px; ">
              <el-row>
                <el-col :span="3">
                  <div class="title3">车牌</div>
                </el-col>
                <el-col :span="21">
                  <div class="content3">{{ poundData.carNo }}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="title3">单号</div>
                </el-col>
                <el-col :span="21">
                  <div class="content3">{{ poundData.billNo }}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="title3">物料</div>
                </el-col>
                <el-col :span="21">
                  <div class="content3">{{ poundData.itemCode }}-{{ poundData.itemName }}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="title3">毛重</div>
                </el-col>
                <el-col :span="21">
                  <div class="content3">
                    {{ poundData.reGrossWeight ? poundData.reGrossWeight + "*" : (poundData.grossWeight ?
                      poundData.grossWeight : (poundData.inDirection == 0 && poundData.weight ? poundData.weight : '')) }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="title3">皮重</div>
                </el-col>
                <el-col :span="21">
                  <div class="content3">
                    {{ poundData.reTareWeight ? poundData.reTareWeight + "*" : (poundData.tareWeight ?
                      poundData.tareWeight : (poundData.inDirection == 1 && poundData.weight ? poundData.weight : '')) }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="title3">净重</div>
                </el-col>
                <el-col :span="21">
                  <div class="content3">{{ (poundData.grossWeight || poundData.reGrossWeight) &&
                    (poundData.tareWeight || poundData.reTareWeight) ? ((poundData.reGrossWeight ? poundData.reGrossWeight
                      : poundData.grossWeight) - (poundData.reTareWeight ? poundData.reTareWeight : poundData.tareWeight)) :
                    '' }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="title3">进厂</div>
                </el-col>
                <el-col :span="21">
                  <div class="content3">{{ poundData.grossWeightTime ? poundData.grossWeightTime :
                    (poundData.inDirection == 0 && poundData.createTime ? poundData.createime : '') }}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <div class="title3">出厂</div>
                </el-col>
                <el-col :span="21">
                  <div class="content3">{{ poundData.tareWeightTime ? poundData.tareWeightTime : (poundData.inDirection == 1
                    && poundData.createTime ? poundData.createTime : '') }}</div>
                </el-col>
              </el-row>
              <div ref="piBox" :style="'height:' + poundImageParam.height + 'px;width:100%;overflow:hidden'">
                <div style="position:relative">
                  <div ref="piInner"
                    :style="'position:absolute;height:' + poundImageParam.height + 'px;width:' + poundImageParam.width + 'px'">
                    <div v-for="(item, index) in poundData.images" :key="index" style="float:left">
                      <el-image :style="'height:' + poundImageParam.height + 'px;width:' + poundImageParam.height + 'px'"
                        :src="item" :preview-src-list="poundData.images">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- <el-dialog :visible.sync="dialogVisible" width="85%">
      <img style="width:300px;margin-left:30px;margin-top:0px" src="../assets/images/shuoming.png">
      <h1 style="margin-left:45%">说明</h1><br><br>
      <div>这里收集了之前访问本系统的各位老板们的一些疑问，总结一下几点。</div><br><br>
      <div style="width:100%; display:inline-block;">
        <div style="font-size:15px;font-weight:bold;width:65%; float:left">
          <div>1、配合移动端（PDA）完成整个流程的体验。地址：<a
              href="https://test.njbanner.cn/api/file/files/pda/bannerWms.apk">https://test.njbanner.cn/api/file/files/pda/bannerWms.apk</a>
          </div>
          <br>
          <div>2、打印功能需下载打印插件（LODOP）。</div> <br>
          <div>3、本团队可提供该系统的二次开发实施服务。</div><br>
          <div>4、本系统支持对接各类ERP/OMS/MES等系统。</div><br>
          <div>5、商业合作: 孙 13951853188</div><br>
        </div>
        <div style="font-size:15px;font-weight:bold;width:35%; float:left;margin-top:-120px">
          <div style="float:left; margin-top:20px">
            <img style="width: 100px; height: 100px" src="../assets/images/PDA.png" fit="fill">
            <div>扫码体验PDA</div>
          </div>
          <div style="float:left;margin-left:100px;margin-top:20px">
            <img style="width: 100px; height: 100px" src="../assets/images/weixin.jpg" fit="fill">
            <div>加微信获取用户手册</div>
          </div>
        </div>
        <div style="font-size:15px;font-weight:bold;width:35%; float:left;margin-top:50px">
          <div style="float:left;margin-top:20px">
            <img style="width: 100px; height: 100px" src="../assets/images/wxapp.jpg" fit="fill">
            <div>微信小程序</div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="bzts()">不再提示</el-button>
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <div class="box" style="padding :5px 10px 10px 20px">
      <el-tabs type="card">
        <el-tab-pane label="入库异常">
          <div class="tableBox none">
            <el-table :data="exList" style="width: 100%">
              <el-table-column type="index" width="30">
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="itemName" label="缩略图/产品编码/产品名称" min-width="300">
                <template slot-scope="scope">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <div style="display: inline-block;margin-left:5px;  width:80px;height:80px">
                        <el-image style="width: 80px; height: 80px" :src="scope.row.imgUrl"
                          :preview-src-list="[scope.row.imgUrl]">
                        </el-image>
                      </div>
                    </el-col>
                    <el-col :span="16">
                      <div style="display: inline-block;margin-left:5px;  ">
                        <span class="textBtn" style="color:red">{{ scope.row.itemCode }}</span>
                        <br />
                        <span>{{ scope.row.itemName }}</span>
                        <br />
                        <span v-if="scope.row.shipBillNo != null">{{ scope.row.shipBillNo }}</span>
                        <br />
                        <span>{{ scope.row.billNo }}</span>
                        <br />
                      </div>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="billNo" label="异常信息" min-width="230">
                <template slot-scope="scope">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <div style="display: inline-block;margin-left:5px;  width:80px;height:80px">
                        <el-image style="width: 80px; height: 80px" :src="scope.row.imgUrls[0]"
                          :preview-src-list="scope.row.imgUrls">
                        </el-image>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <span>类型: </span>
                      <span class="textBtn" v-if="scope.row.type == 1">包裹异常</span>
                      <span class="textBtn" v-if="scope.row.type == 2">物料异常</span>
                      <br>
                      <span>原因: </span>
                      <span class="textBtn" v-if="scope.row.reason == 1">多发</span>
                      <span class="textBtn" v-if="scope.row.reason == 2">少发</span>
                      <span class="textBtn" v-if="scope.row.reason == 3">错发</span>
                      <span class="textBtn" v-if="scope.row.reason == 4">破损</span>
                      <span class="textBtn" v-if="scope.row.reason == 5">其他</span>
                      <br>
                      <span>描述: </span>
                      <span class="textBtn">{{ scope.row.memo }}</span>
                      <br>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="billNo" label="处理方案" min-width="180">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.fangan == 1">全部退件</el-tag>
                  <el-tag type="danger" v-if="scope.row.fangan == 2">全部弃件</el-tag>
                  <el-tag type="success" v-if="scope.row.fangan == 3">全部入库</el-tag>
                  <el-tag type="warning" v-if="scope.row.fangan == 4">部分入库部分退件</el-tag>
                  <el-tag type="danger" v-if="scope.row.fangan == 5">部分入库部分弃件</el-tag>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="expectQuantity" label="数量" min-width="130">
                <template slot-scope="scope">
                  订单数：<span style="color:#409EFF;margin-left:2px;font-size:20px">{{ scope.row.orderQuantity / 1 }} </span>
                  <br>
                  已入库数：<span style="color:#409EFF;margin-left:2px;font-size:20px">{{ scope.row.inQuantity / 1 }} </span>
                  <br>
                  异常数：<span style="color:red;margin-left:2px;font-size:20px">{{ scope.row.quantity / 1 }} </span>
                  <br>
                  <br>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="toQuantity" label="验收信息/异常信息" min-width="200">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.state == 0" type="success">初始化 </el-tag>
                  <el-tag v-if="scope.row.state == 1" type="success">提交ERP </el-tag>
                  <el-tag v-if="scope.row.state == 2" type="danger">待处理 </el-tag>
                  <el-tag v-if="scope.row.state == 3" type="success">已经处理 </el-tag>
                  <div style="width:90%" v-if="scope.row.state == 2">
                    入库数量：<el-input-number :controls='controls' @focus="input0Set" v-model="scope.row.toQuantity"
                      controls-position="right" min=0 placeholder="" label="请输入数量" size="small"
                      style="width: 90%;"></el-input-number>
                    <div style="height:5px"></div>
                    打印数量： <el-input-number :controls='controls' @focus="input0Set" v-model="scope.row.printQuantity"
                      controls-position="right" min=0 label="请输入数量" size="small" style="width: 90%;"></el-input-number>
                    <div style="height:5px"></div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="pagination-bottom" style="text-align: right;" @current-change="exCurrentChange"
              :current-page.sync="exSearchData.pageNum" :page-size="exSearchData.pageSize"
              layout="total, prev, pager, next, jumper" :total="exSearchData.total">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待上架库存">
          <div>
            <el-table highlight-current-row :data="upList" style="width: 100%">
              <el-table-column fixed="left" show-overflow-tooltip prop="inventoryId" label="库存ID" min-width="100">
              </el-table-column>
              <el-table-column fixed="left" show-overflow-tooltip prop="itemName" label="物料名称" min-width="150">
                <template slot-scope="scope">
                  <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="itemCode" label="物料编码" min-width="130">
                <template slot-scope="scope">
                  <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemCode
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="organizationName" label="工厂" min-width="130">
                <template slot-scope="scope">
                  <span class="textBtn" @click="$parent.showPublicModal(scope.row.organizationId, 'organization')">{{
                    scope.row.organizationName }}</span>
                </template>
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="batchName" label="批次" min-width="130">
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="quantity" label="数量" min-width="140">
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="allotQuantity" label="分配数量" min-width="140">
                <template slot-scope="scope">
                  <span style="color:black;font-weight:900;">{{ scope.row.allotQuantity }} </span>
                  <span v-if="scope.row.allotQuantity > 0" style="font-size:20% ;color:red ">爆</span>
                </template>
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="cellCode" label="货位" min-width="150">
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="boxCode" label="追踪号" min-width="150">
              </el-table-column>
            </el-table>
            <el-pagination class="pagination-bottom" style="text-align: right;" @current-change="upCurrentChange"
              @size-change="upSizeChange" :current-page.sync="upSearchData.pageNum" :page-sizes="[15, 30, 50, 100]"
              :page-size="upSearchData.pageSize" layout="sizes,total, prev, pager, next" :total="upSearchData.total">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待拣波次">
          <div class="tableBox  ">
            <el-table :data="waveMasters" style="width: 100%">
              <el-table-column sortable prop="code" label="波次编码">
                <template slot-scope="scope">
                  <div slot="header" class="clearfix">
                    <span>{{ scope.row.waveId }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="生成时间" min-width="150">
              </el-table-column>
              <el-table-column prop="totalBillCount" label="订单总数" min-width="150">
              </el-table-column>
              <el-table-column prop="totalSkuCount" label="sku总数" min-width="150">
              </el-table-column>
              <el-table-column prop="waveTacticName" label="波次策略" min-width="150">
                <template slot-scope="scope">
                  {{ scope.row.waveTacticName }}
                  <span v-if="scope.row.soStructure == 1">（一单一品）</span>
                  <span v-if="scope.row.soStructure == 2">（ 一单多品）</span>
                  <span v-if="scope.row.soStructure == 3">（ 一单一品一件）</span>
                  <span v-if="scope.row.soStructure == 4">（一单一品多件）</span>
                </template>
              </el-table-column>
              <el-table-column prop="isSeeding" label="分拣方式：" min-width="150">
                <template slot-scope="scope">
                  <span v-if="scope.row.isSeeding == 1">先拣后分</span>
                  <span v-if="scope.row.isSeeding == 0">边拣边分</span>
                </template>
              </el-table-column>
              <el-table-column prop="state" label="状态" min-width="150">
                <template slot-scope="scope">
                  <span style="color:#F56C6C" v-if="scope.row.state == 0">订单接收中</span>
                  <span style="color:#8B3A62" v-if="scope.row.state == 1">待拣货</span>
                  <span style="color:#8B3A62" v-if="scope.row.state == 11">拣货中</span>
                  <span style="color:#543044" v-if="scope.row.state == 2">拣货完成</span>
                  <span style="color:#E6A23C" v-if="scope.row.state == 3">播种完成</span>
                  <span style="color:#67C23A" v-if="scope.row.state == 4">发运完成</span>
                  <span style="color:#67C23A" v-if="scope.row.state == 5">结束</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination style="text-align: right;" class="pagination-bottom" @current-change="waveCurrentChange"
              :current-page.sync="waveSearchData.pageNum" :page-size="waveSearchData.pageSize"
              @size-change="waveSizeChange" :page-sizes="[15, 30, 50, 100]" layout="sizes,total, prev, pager, next  "
              :total="waveSearchData.total">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="还货上架">
          <div>
            <el-table :data="pickTaskReturns" style="width: 100%">
              <el-table-column fixed="left" show-overflow-tooltip prop="itemName" label="物料名称" min-width="150">
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="itemCode" label="物料编码" min-width="130">
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="quantity" label="数量" min-width="70">
                <template slot-scope="scope">
                  <span>{{ scope.row.quantity }}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="boxCode" label="boxCode" min-width="70">
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="fromInventoryId" label="fromInventoryId" min-width="70">
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="toCellCode" label="推荐货位" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.toCellCode }}</span>
                </template>
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="state" label="状态" min-width="80">
                <template slot-scope="scope">
                  <span v-if="scope.row.state == 0" style="color:#E6A23C">待上架</span>
                  <span v-if="scope.row.state == 1" style="color:#67C23A">已完成</span>
                </template>
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="type" label="类型" min-width="80">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 1" style="color:#909399">配货异常</span>
                  <span v-if="scope.row.type == 2" style="color:#F56C6C">订单修改（未发运）</span>
                  <span v-if="scope.row.type == 3" style="color:#F56C6C">订单修改（已发运）</span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" min-width="120">
                <template slot-scope="scope">
                  <el-button @click="pickTaskAffirm(scope.row)" type="primary" size="small"
                    :disabled="scope.row.state === 2">&nbsp;拣货确认</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="pagination-bottom" style="text-align: right;" @current-change="returnCurrentChange"
              :current-page.sync="returnSearchData.pageNum" :page-size="returnSearchData.pageSize"
              @size-change="returnSizeChange" :page-sizes="[15, 30, 50, 100]"
              layout="sizes,total, prev, pager, next, jumper" :total="returnSearchData.total">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="补货建议">
          <div>
            <el-table v-loading="loading.cprfTable" highlight-current-row :data="cpfrTasks" style="width: 100%">
              <el-table-column fixed="left" show-overflow-tooltip prop="itemName" label="物料名称" min-width="150">
                <template slot-scope="scope">
                  <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="itemCode" label="物料编码" min-width="130">
                <template slot-scope="scope">
                  <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemCode
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="quantity" label="数量" min-width="70">
                <template slot-scope="scope">
                  <span>{{ scope.row.quantity }}</span>
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" min-width="100">
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="fromCellCode" label="FROM货位" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.fromCellCode }}</span>
                </template>
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="toCellCode" label="TO货位" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.toCellCode }}</span>
                </template>
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="type" label="来源" min-width="80">
                <template slot-scope="scope">
                  <span v-if="scope.row.type == 1">配置驱动</span>
                  <span v-else-if="scope.row.type == 2">订单驱动</span>
                </template>
              </el-table-column>
              <el-table-column sortable show-overflow-tooltip prop="state" label="状态" min-width="80">
                <template slot-scope="scope">
                  <el-tag v-for="(item, index) in cpfrTaskTypes" :key="index"
                    v-if="scope.row.state === item.value">{{ item.label }}</el-tag>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="pagination-bottom" style="text-align: right;" @current-change="cpfrCurrentChange"
              :current-page.sync="cpfrSearchData.pageNum" :page-size="cpfrSearchData.pageSize"
              @size-change="cpfrSizeChange" :page-sizes="[15, 30, 50, 100]"
              layout="sizes,total, prev, pager, next, jumper" :total="cpfrSearchData.total">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div> -->
  </div>
</template>

<script>
import print from "print-js";
import { mapState } from "vuex";

import EZUIKit from "ezuikit-js";
var player = null;//播放器注册

var echarts = require("echarts");

export default {
  name: "Homepage",
  data() {
    return {
      typeKey: 'deviceCapDirKey',
      upUrl: BASEIP + '/upload/files',
      rtUrl: BASEIP + "/file/files",
      rlPath: '',

      count: {
        so: {},
        asn: {},
        asnEx: {}
      },
      percent: {
        perPickTask: 0,
        perUpTask: 0,
        perPoundTask: 0,
        perPdTask: 0,
      },
      dialogVisible: false,
      wareInfos: [],
      loading: {
        cprfTable: false,
      },
      cpfrTasks: [],
      cpfrSearchData: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        state: 0,
        wareId: PF.getLocal('wareId', 'number'),
      },

      accessDevices: [],
      videoDevices: [],
      poundDevices: [],

      accessKey: 0,
      videoKey: null,
      poundKey: 0,

      videoParam: {},
      accessLog: [],
      poundData: {},

      poundImageParam: {
        height: 60,
        width: 60,
      },
      scrollTimer: null,
      scrollStep: 2,

      upList: [],
      upSearchData: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        cellUseType: 0,
        wareId: PF.getLocal('wareId', 'number'),
        organizationId: PF.getLocal('organizationId', 'number'),
      },

      exList: [],
      exSearchData: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        state: 0,
        wareId: PF.getLocal('wareId', 'number'),
      },
      pickTaskReturns: [],
      returnSearchData: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        state: 0,
        wareId: PF.getLocal('wareId', 'number'),
      },
      waveMasters: [],
      waveSearchData: {
        state: 1,
        pageNum: 1,
        pageSize: 10,
        total: null,

        wareId: PF.getLocal('wareId', 'number')
      },
      soCount: 0,
      shippedCount: 0,
      unShippedCount: 0,
      acceptCount: 0,
      qcCount: 0,
      searchObj: {
        days: 7,
        wareId: PF.getLocal('wareId', 'number'),
        organizationId: PF.getLocal('organizationId', 'number'),
        organizationCode: PF.getLocal('organizationCode'),
      },
      daysOptions: [{
        value: 1,
        label: '1天'
      }, {
        value: 2,
        label: '2天'
      }, {
        value: 3,
        label: '3天'
      }, {
        value: 4,
        label: '4天'
      }, {
        value: '5',
        label: '5天'
      }, {
        value: '6',
        label: '6天'
      }, {
        value: 7,
        label: '7天'
      }],
      radioSpan: 0,
      config: {
        data: [69],
        shape: "roundRect",
      },
      config5: {
        value: 66,
        textColor: "black",
      },
      mainHeight:275,
      option: {
        color: [
          "#0090FF",
          "#36CE9E",
          "#FFC005",
          "#FF515A",
          "#8B5CFF",
        ],
        title: {
          text: "操作统计",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["发货", "入库", "出库", "盘点"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "周一",
            "周二",
            "周三",
            "周四",
            "周五",
            "周六",
            "周日",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            smooth: true,
            name: "发货",
            type: "line",

            data: [820, 932, 901, 934, 1290, 1330, 1320],
          },
          {
            smooth: true,
            name: "入库",
            type: "line",

            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            smooth: true,
            name: "出库",
            type: "line",

            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            smooth: true,
            name: "盘点",
            type: "line",

            data: [150, 232, 201, 154, 190, 330, 410],
          },
        ],
      },

      // productList: this.$store.state.productList // 获取store.js > state > productList
    };
  },
  watch: {
    appVisible(val) {
      if (!val && player != null && player.stop) player.stop();
    }
  },
  computed: {
    ...mapState(["productList"]),
  },
  methods: {
    getImageList(wtImg) {
      let fileList = [];
      if (wtImg) {
        let data = wtImg.split(',');
        //每循环一次就把后台返回的图片地址添加到filesList数组	
        for (let d of data) {
          fileList.push(this.rtUrl + this.rlPath + d);
        }
      }
      return fileList;
    },
    bzts() {
      this.dialogVisible = false;
      PF.setLocal('dialogVisible', 'false');
    },
    resendCode() {
      localStorage.setItem("dialogVisible", "true");
      this.dialogVisible = true;
      // debugger
      // var that = this;
      //  window.IOT.getServerData('/shops/getAuthPartnerUrl', 'get', {
      //     organizationId: that.organizationId,
      //     platformType: 2,
      //     resend:true
      // }, (ret) => {
      //     if (ret.code === 200) { 
      //         var authUrl = ret.data;
      //         window.open(authUrl + "&country=" + this.lazadaCountryCode);
      //     } else {
      //         window.IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
      //     }
      // }, true);
    },

    cpfrCurrentChange(val) {
      this.cpfrSearchData.pageNum = val;
      this.getPickTaskData()
    },
    cpfrSizeChange(val) {
      this.cpfrSearchData.pageSize = val;
      this.getPickTaskData();
    },
    getPickTaskData() {
      let that = this;
      that.loading.cpfrTable = true;
      IOT.getServerData('/cpfr/tasks/list', 'get', this.cpfrSearchData, (ret) => {
        if (ret.code === 200) {
          let list = ret.rows;
          that.cpfrTasks = list;
          that.cpfrSearchData.total = ret.total;
          that.cpfrSearchData.pageNum = ret.pageNumber
          that.loading.cpfrTable = false;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },

    upCurrentChange(val) {
      this.upSearchData.pageNum = val;
      this.getUp()
    },
    exCurrentChange() {

    },
    upSizeChange(val) {
      this.upSearchData.pageSize = val;
      this.getUp();
    },
    getUp() {
      let that = this;
      let upSearchData = PF.JSON(this.upSearchData);
      IOT.getServerData('/inventorys/CellUseTypeList', 'get', upSearchData, (ret) => {
        that.loading.masterTable = false;
        if (ret.code === 200) {
          that.upList = ret.rows;
          that.upSearchData.total = ret.total;
          that.upSearchData.pageNum = ret.pageNumber
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    returnCurrentChange(val) {
      this.returnSearchData.pageNum = val;
      this.getPickTaskReturn()
    },
    returnSizeChange(val) {
      this.returnSearchData.pageSize = val;
      this.getPickTaskReturn();
    },
    getPickTaskReturn() {
      let that = this;
      IOT.getServerData('/pick/task/returns/findList', 'get', this.returnSearchData, (ret) => {
        if (ret.code === 200) {
          let list = ret.rows;
          this.pickTaskReturns = list;
          this.returnSearchData.total = ret.total;
          this.returnSearchData.pageNum = ret.pageNumber
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    waveCurrentChange(val) {
      this.waveSearchData.pageNum = val;
      this.getPickTaskReturn()
    },
    waveSizeChange(val) {
      this.waveSearchData.pageSize = val;
      this.getPickTaskReturn();
    },
    getWaveMasterData() {
      let that = this;
      IOT.getServerData('/wave/masters/list', 'get', this.waveSearchData, (ret) => {
        that.loading.parent = false
        if (ret.code === 200) {
          that.waveMasters = ret.rows;
          that.waveSearchData.total = ret.total;
          that.waveSearchData.pageNum = ret.pageNumber;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    changeAccess() {
      if (this.accessDevices && this.accessDevices.length > 0) {
        if (null == this.accessKey || this.accessKey < 0 || this.accessKey > this.accessDevices.length) {
          this.accessKey = 0;
        }
        this.getAccessData();
      }
    },
    videoDestroy() {
      if (player != null) {
        if (player.stop) player.stop();
        //if(player.destroy) player.destroy();
      }
      player = null;
    },
    changeVideo() {
      if (!PF.isNull(this.videoKey)) {
        if (player != null && player.stop) {player.stop();}
        return;
      }
      if (this.videoDevices && this.videoDevices.length > 0) {
        if (this.videoKey < 0 || this.videoKey > this.videoDevices.length) {
          this.videoKey = 0;
        }
        this.getVideoData();
      }
    },
    changePound() {
      if (this.poundDevices && this.poundDevices.length > 0) {
        if (null == this.poundKey || this.poundKey < 0 || this.poundKey > this.poundDevices.length) {
          this.poundKey = 0;
        }
        this.getPoundData();
      }
    },
    getAccessData() {
      this.accessLog = [];
      if (this.accessKey >= this.accessDevices.length) return;
      var row = this.accessDevices[this.accessKey];
      IOT.getServerData('/device/events/list', 'get', { deviceId: row.id, pageNum: 1, pageSize: 10, }, (ret) => {
        if (ret.code === 200) {
          this.accessLog = ret.rows;
          console.log(this.accessLog)
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getVideoData() {
      this.videoParam = {};
      if (this.videoKey >= this.videoDevices.length) return;
      var row = this.videoDevices[this.videoKey];
      IOT.getServerData('/device/details/preview', 'post', row, (ret) => {
        if (ret.code === 200) {
          this.videoParam.url = ret.data.url;
          this.videoParam.token = ret.data.token;
          this.StructureEZUIKitPlayer();
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      }, true);
    },
    getPoundData() {
      let that = this;
      this.poundData = {};
      if (this.poundKey >= this.poundDevices.length) return;
      var row = this.poundDevices[this.poundKey];
      IOT.getServerData('/pounds/currentPound', 'get', { deviceNo: row.devName, pageNum: 1, pageSize: 1, }, (ret) => {
        if (ret.code === 200) {
          if (ret.rows && ret.rows.length > 0) {
            that.poundData = ret.rows[0];
            if (that.poundData.billNo) {
              that.poundData.images = that.getImageList(that.poundData.grossInImg).concat(that.getImageList(that.poundData.grossWtImg))
                .concat(that.getImageList(that.poundData.tareInImg)).concat(that.getImageList(that.poundData.tareWtImg));
            } else {
              that.poundData.images = that.getImageList(that.poundData.inImg).concat(that.getImageList(that.poundData.wtImg))
            }
            that.initPoundImage();
          }
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    StructureEZUIKitPlayer() {
      if (!PF.isNull(this.videoParam)) return;
      if (player != null && player.stop) {
        player.stop().then(() => {
          player.play({ url: this.videoParam.url, accessToken: this.videoParam.token });
        });
      } else {
        this.initEZUIKitPlayer();
      }
    },
    initPoundImage() {
      if (this.$refs.poundData && this.poundData && this.poundData.images) {
        this.poundImageParam.height = (this.$refs.poundData.offsetHeight - 220);
        this.poundImageParam.width = this.poundImageParam.height * this.poundData.images.length;
        this.stopRoll();
        this.startRoll();
      }
    },
    initEZUIKitPlayer() {
      if (!PF.isNull(this.videoParam)) return;
      if (player != null && player.stop) player.stop();
      var width = 300;
      var height = 225;
      if (this.$refs && this.$refs.videoContainer) {
        width = this.$refs.videoContainer.offsetWidth - 15;
        height = this.$refs.videoContainer.offsetHeight - 30;
      }
      console.log(width + "," + height);
      // 渲染视频播放
      player = new EZUIKit.EZUIKitPlayer({
        id: 'myPlayer', // 视频容器ID
        accessToken: this.videoParam.token,
        url: this.videoParam.url, // 初始化写死一个离线或者找不到的设备，避免初始化无法创建播放器；
        template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
        //plugin: ['talk'],  
        //header: ["capturePicture", "save"], // 如果templete参数不为simple,该字段将被覆盖                     // 加载插件，talk-对讲
        //footer: ['hd','fullScreen'], // 如果template参数不为simple,该字段将被覆盖
        width: width,
        height: height,
        //QQ浏览器全屏有问题，右侧下方有黑边，还有报错需要处理
        // fullScreenCallBack: function (data) {
        //   return console.log("全屏回调", data);
        // }, // 如果template参数不为simple, 无法获取
        //capturePictureCallBack: data => console.log("截图成功回调", data),
      });
      window.player = player;
    },
    getQuarterSeasonStartMonth(month) {
      // 得到本季度开始的月份
      var spring = 0 // 春
      var summer = 3 // 夏
      var fall = 6 // 秋
      var winter = 9 // 冬
      // 月份从0-11
      if (month < 3) {
        return spring
      }
      if (month < 6) {
        return summer
      }
      if (month < 9) {
        return fall
      }
      return winter
    },
    initOptionData(start, end) {
      this.option.xAxis.data = [];
      for (var i = 0; i < this.option.series.length; i++) {
        this.option.series[i].data = [];
      }
      if (PF.isNull(start) && PF.isNull(end)) {
        for (; start < end;) {
          this.option.xAxis.data.push(PF.getNowFormatDate(start).substring(2));
          for (var i = 0; i < this.option.series.length; i++) {
            this.option.series[i].data.push(0);
          }
          start = new Date(start.getTime() + 1 * 24 * 60 * 60 * 1000);
        }
      }
    },
    getSeachObj() {

    },
    fetchVideo() {
      this.videoDevices = [];
      this.accessDevices = [];
      this.poundDevices = [];
      IOT.getServerData('/device/details/list', 'get', { organizationCode: PF.getLocal('organizationCode') }, (ret) => {
        if (ret.code === 200) {
          if (ret.rows && ret.rows.length > 0) {
            ret.rows.forEach(element => {
              if (element.devType == 0) {
                this.videoDevices.push(element);
              } else if (element.devType == 1) {
                this.accessDevices.push(element);
              } else if (element.devType == 2) {
                this.poundDevices.push(element);
              }
            });
          }
          // this.changeVideo();
          this.changePound();
          this.changeAccess();
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    roll() {
      if (this.$refs.piBox && this.$refs.piInner) {
        if (this.$refs.piInner.offsetWidth < this.$refs.piBox.offsetWidth) {
          this.$refs.piInner.style.left = "0px";
          return;
        }
        if (this.scrollStep > 0) {
          if (this.$refs.piInner.offsetWidth + this.$refs.piInner.offsetLeft - this.scrollStep < this.$refs.piBox.offsetWidth) {
            this.scrollStep = -this.scrollStep;
          }
        } else {
          if (this.$refs.piInner.offsetLeft - this.scrollStep > 0) {
            this.scrollStep = -this.scrollStep;
          }
        }
        this.$refs.piInner.style.left = (this.$refs.piInner.offsetLeft - this.scrollStep) + "px";
      }
    },
    stopRoll() {
      if (this.scrollTimer) clearInterval(this.scrollTimer);
    },
    startRoll() {
      this.scrollTimer = setInterval(this.roll, 100);
    },
    fetchData() {
      let that = this;
      var today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      //date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日"
      this.searchObj.endTime = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate() + " 00:00:00";
      var startday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      if (this.radioSpan === "1") {
        startday = new Date(today.getFullYear(), today.getMonth(), 1);
      } else if (this.radioSpan === "2") {
        startday = new Date(today.getFullYear(), this.getQuarterSeasonStartMonth(today.getMonth()), 1);
      }
      this.searchObj.startTime = startday.getFullYear() + "-" + (startday.getMonth() + 1) + "-" + startday.getDate() + " 00:00:00";
      // this.option.xAxis.data=this.getDateArray(startday,today);
      this.initOptionData(startday, today);

      IOT.getServerData("/home/statistics/topStatistics", "post", this.searchObj, (ret) => {
        if (ret.code === 200) {
          console.log(ret.data);
          let data = ret.data;
          for (var i = 0; i < that.option.xAxis.data.length; i++) {
            if (data.sendCountList) {
              data.sendCountList.forEach(item => {
                if (that.option.xAxis.data[i] === item.dateStr) {
                  that.option.series[0].data[i] = item.countData;
                  return;
                }
              });
            }
            if (data.acceptCountList) {
              data.acceptCountList.forEach(item => {
                if (that.option.xAxis.data[i] === item.dateStr) {
                  that.option.series[1].data[i] = item.countData;
                  return;
                }
              });
            }
            if (data.soCountList) {
              data.soCountList.forEach(item => {
                if (that.option.xAxis.data[i] === item.dateStr) {
                  that.option.series[2].data[i] = item.countData;
                  return;
                }
              });
            }
            if (data.pdCountList) {
              data.pdCountList.forEach(item => {
                if (that.option.xAxis.data[i] === item.dateStr) {
                  that.option.series[3].data[i] = item.countData;
                  return;
                }
              });
            }
          }

          that.count.so = data.so;
          that.count.asn = data.asn;

          that.percent.perPickTask = parseInt(data.perPickTask);
          that.percent.perUpTask = parseInt(data.perUpTask);
          that.percent.perPoundTask = parseInt(data.perPoundTask);
          that.percent.perPdTask = parseInt(data.perPdTask);
          that.percent.perInventoryTurnOver = parseInt(data.perInventoryTurnOver);
          var cd = { data: [] };
          cd.data.push(parseInt(data.perUsedCell));
          that.config = cd;
          that.initEcharts();
        } else {
          IOT.tips(
            ret.message || "服务器请求失败，稍后再试！",
            "error"
          );
        }
      }, true);
    },
    formatDate(row, column) {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return "";
      }
      let dt = new Date(data)
      return PF.getNowFormDateTime(dt)
      //return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
    },
    changeSpan(val) {
      this.fetchData();
    },
    changeDays(val) {
      this.fetchData()
    },
    printTest2() {
      var aaa = App.baseIp;
      printJS("deviceId", "html");
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    initEcharts() {
      var myChart = echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(this.option);
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (player != null && player.stop) {
        this.initEZUIKitPlayer();
        // this.mainHeight = window.innerHeight-700;
      }
      this.initPoundImage();

      // this.mainHeight = window.innerHeight-700;
    })
    this.fetchData();
    this.fetchVideo();
  },
  created() {
    let token = localStorage.getItem("token");
    if (token == undefined || token == null || token == '') {
      return;
    }

    let dialogVisible = localStorage.getItem("dialogVisible");
    if (dialogVisible == undefined || dialogVisible == null || dialogVisible == '') {

    } else if (dialogVisible == "false") {
      this.dialogVisible = false;
    }

    PF.getRelativePath(this.typeKey, (path) => {
      this.rlPath = path;
    });

    // this.getPickTaskData();
    // this.getUp();
    // this.getPickTaskReturn();
    // this.getWaveMasterData();
  },
  beforeDestroy() {
    this.videoDestroy();
    this.stopRoll();
  },

};
</script>

<style scoped lang="less">
.homepage {
  height: 1900px;

  .box {
    //   box-shadow: 0 2px 4px #c1deff, 0 0 6px #c1deff;

    border-radius: 10px;
    margin: 10px 15px 5px 8px;
    padding: 0px 20px 10px 0px;

    background-color: white;

    .left_icon {
      position: absolute;
      margin: 30px 20px;
    }

    .right {
      position: absolute;
      margin: 10px 10px;
      left: 45%;

      .right1 {
        font-size: 14px;
        margin: 0px 0px 10px 0px;
      }

      .right2 {
        font-size: 18px;
        font-weight: 700;
        margin: 5px 0px 10px 0px;
      }
    }

    .left_icon:before {
      font-size: 1cm;
    }
  }

  .box2 {
    margin: 10px 15px 0px 0px;
    padding: 10px 0px 0px 15px;
    border-radius: 5px;
    height: 80px;
  }

  .box3 {
    margin: 10px 15px 0px 15px;
    padding: 2px 15px 0px 15px;
    border-radius: 5px;

    .title {
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      margin: 12px 0px 0px 25px;
      font-weight: 700;
    }

    .title2 {
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      margin: 0px 0px 5px 0px;
      font-weight: 700;
      font-size: 20px;
    }

    .el-table .warning-row {
      background: oldlace;
    }

    .el-table .success-row {
      background: #f0f9eb;
    }

    .title3 {
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      margin: 8px 0px 4px 0px;
      font-weight: 550;
      font-size: 12px;
    }

    .content3 {
      margin: 8px 0px 4px 0px;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .el-progress {
    width: 80%;
    line-height: 2;
    // display: inline;
  }

  .el-tabs--card {
    border: none;
  }


}
</style>
 